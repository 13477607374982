import { HowToPlay } from "../types/modalTypes";

export const howToPlayData: HowToPlay[] = [
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    index: "howToPlay1",
    type: "default",
    textMVArray: [
      {
        kanji:
          "まずはステラプレイスの<span class='font-bold text-red-500'>5つの階にある謎</span>に挑戦しよう！どこから挑戦しても大丈夫だよ！",
        furigana: "",
      },
      {
        kanji:
          "各階のどこかにあるQRコードを見つけることができると、謎が現れるよ。",
        furigana: "",
      },
      {
        kanji:
          "5つの謎を解くことで<span class='font-bold text-red-500'>最後の謎</span>に挑戦できるようになるよ！すべてクリアすると<span class='font-bold text-red-500'>20名様に1万円のショッピングチケット</span>が当たる抽選の応募券がもらえるよ！",
        furigana: "",
      },
      {
        kanji: "制限時間はないからじっくり考えてね。",
        furigana: "",
      },
      {
        kanji:
          "困った時はメニューの中にあるヘルプを確認してね！ヒントもあるよ。",
        furigana: "",
      },
      {
        kanji:
          "クリア履歴は保存することができるよ。これも詳しくはメニューの中のヘルプを見てね。",
        furigana: "",
      },
    ],
  },
];
