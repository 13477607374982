import { Help, Setting } from "./commonTypes";
import { Item, Ticket } from "./modalTypes";
import { Spot } from "./spotTypes";
import {
  UserAnsweredSurvey,
  UserGainedItem,
  UserGainedTicket,
} from "./userTypes";

export const isSpot = (value: unknown): value is Spot => {
  return !!(value as Spot)?.spotNameMV?.kanji;
};

export const isItem = (value: unknown): value is Item => {
  return !!(value as Item)?.itemNameMV?.kanji;
};

export const isUserGainedItem = (value: unknown): value is UserGainedItem => {
  return !!(value as UserGainedItem)?.itemNameMV?.kanji;
};

export const isTicket = (value: unknown): value is Ticket => {
  return !!(value as Ticket)?.ticketNameMV?.kanji;
};

export const isUserGainedTicket = (
  value: unknown
): value is UserGainedTicket => {
  return !!(value as UserGainedTicket)?.ticketNameMV?.kanji;
};

export const isSettingState = (value: unknown): value is Setting => {
  return !!(value as Setting)?.radarMapStyleUrl;
};

export const isHelp = (value: unknown): value is Help => {
  return !!(value as Help)?.helpTitleMV?.kanji;
};

export const isUserAnsweredSurvey = (
  value: unknown
): value is UserAnsweredSurvey => {
  return !!(value as UserAnsweredSurvey)?.surveyName;
};

export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((val) => typeof val === "string");
};
