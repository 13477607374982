import { Help } from "../../../../common/types/commonTypes";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  help: Help;
}

const Default: React.FC<Props> = ({ help }) => {
  const { helpTitleMV, helpTextMVArray } = help;

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={helpTitleMV} />
        </div>
      </div>
      {helpTextMVArray.map((text, index) => {
        return (
          <div key={text?.kanji + index} className={styles.text}>
            <MultipleViewText text={text} />
          </div>
        );
      })}
    </div>
  );
};

export default Default;
