import { Answer } from "../types/questTypes";

export const answerData: Answer[] = [
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content1",
    index: "answer1",
    answerArray: ["センター"],
    questHintMVArray: [
      {
        kanji:
          "近くにある掲示物（Directory）を調べよう！左右どちらを選んだかを覚えておいてね。",
        furigana: "",
      },
    ],
    explanation:
      "表示されているフロアが、右と左のどちらが正しいかを判定し選んでいきます。B1Fの設置場所近くには全館の案内がありますので、困った時はそれを見ることができます。正しい選択肢は左→右→右→左にあるので、最後の表で上から左右右左にある文字を拾って「センター」が答えになります。",
  },
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content2",
    index: "answer2",
    answerArray: ["ソーラー"],
    questHintMVArray: [
      {
        kanji:
          "屋外にある時計の模様を調べて、その模様「だけ」通るように進もう。通った文字を読むと…？",
        furigana: "",
      },
    ],
    explanation:
      "屋外の南向きの外壁にある大時計を見ると、白い星の模様があります。よってSからGまで白い星「だけ」通るように進むと「ソーラー」という答えが出てきます。迷路の画像は大きなサイズで縮小できず、自分でぐりぐり動かして進む必要があります。",
  },
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content3",
    index: "answer3",
    answerArray: ["ホシ"],
    questHintMVArray: [
      {
        kanji:
          "●の位置と星の色に注目しよう。青い星で真上に●があるときは「サ」、赤い星で真上に●があるときは「ハ」になるよ。",
        furigana: "",
      },
    ],
    explanation:
      "それぞれの星の色と●の位置がアイウオエオ表に対応しています。ソラ、ヒロバの手がかりから、青色はサ行、赤色はハ行、黄色はラ行で、真上にある時がアイウエオのアの音、以降時計回りにイウエオに対応していることがわかります（サ行のオの音とラ行のアの音＝ソラ）。よって？？はハ行のオの音とサ行のイの音で「ホシ」が答えになります。",
  },
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content4",
    index: "answer4",
    answerArray: ["コンコース"],
    questHintMVArray: [
      {
        kanji:
          "2003年にあったのは全部で3つ。太字⇄細字、カタカナ⇄ひらがな、黒文字⇄赤文字でペアになっていてどちらか片方だけが選ばれるよ。",
        furigana: "",
      },
    ],
    explanation:
      "2003年の出来事は「JRタワーが開業」「六本木ヒルズオープン」「はやぶさ打ち上げ」です。それぞれタップすると「太文字は読まない」「赤文字は読まない」「ひらがなは読まない」という指示が出てくるので、細文字で黒文字でカタカナを繋げて「コンコース」が答えになります。",
  },
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content5",
    index: "answer5",
    answerArray: ["オクジョウ"],
    questHintMVArray: [
      {
        kanji: "カタカナ5文字が隠れているよ。1文字目は「オ」。",
        furigana: "",
      },
    ],
    explanation:
      "星が並んでいる3枚の画像がアニメーションで順番に表示されています。全てを重ねてカタカナの文字を作ると「オクジョウ」が答えになります。",
  },
  // {
  //   ver: "v1",
  //   orgId: "stellarplace",
  //   course: "nazotoki",
  //   room: "room1",
  //   content: "content6",
  //   index: "answer6",
  //   answerArray: ["れきし"],
  //   questHintMVArray: [
  //     {
  //       kanji:
  //         "もみじの落ち葉がつくと「か」が「き」に、「は」が「ひ」になっているね。何か法則がありそうだ。他の落ち葉にもそれぞれ法則があるよ。",
  //       furigana: "",
  //     },
  //   ],
  //   explanation: "",
  // },
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "final",
    index: "answer7",
    answerArray: ["アラタナキモチ"],
    questHintMVArray: [
      {
        kanji: "アイテムを確認しよう！時計回りに読めるよ。",
        furigana: "",
      },
      {
        kanji:
          "スマートフォンの画面だけでは解けないよ！同じ見た目のものが3階にあるね。いろんな数の塊で星が載っているよ。キーワードはさっき答えたばかりものだけど、この星と対応させられないかな…？正しい手順で進むと解答欄も現れるよ！",
        furigana: "",
      },
    ],
    explanation: "",
  },
];
