import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { helpData } from "../data/helpData";
import { converter, firestore } from "../lib/firebase";
import { Help, Setting } from "../types/commonTypes";
import { useUserAuth } from "./userAuth.context";

interface SettingContext {
  settingState: Setting | null;
  withFurigana: boolean | null;
  setWithFurigana: Dispatch<SetStateAction<boolean | null>>;
  helpDataArray: Help[];
}

interface Props {
  children: ReactNode;
}

const SettingStateContext = createContext<SettingContext | null>(null);

export const SettingStateProvider: React.FC<Props> = ({ children }) => {
  const { uid } = useUserAuth();

  const [settingState, setSettingState] = useState<Setting | null>(null);
  const [withFurigana, setWithFurigana] = useState<boolean | null>(null);
  const [helpDataArray, setHelpDataArray] = useState<Help[]>([]);
  const router = useRouter();

  const {
    query: { ver, orgId, course },
  } = router;

  // 設定データの取得
  useEffect(() => {
    if (!settingState && uid) {
      const ref = doc(
        firestore,
        `${ver}/${orgId}/courses/${course}`
      ).withConverter(converter<Setting>());

      getDoc(ref).then((snap) => {
        if (snap.exists()) {
          console.log("settingState", snap.data());
          setSettingState(snap.data());
        }
      });
    }
  }, [course, orgId, settingState, uid, ver]);

  // 追加ヘルプデータの取得と合体
  useEffect(() => {
    if (!helpDataArray.length && uid) {
      const ref = collection(
        firestore,
        `${ver}/${orgId}/courses/${course}/helpData`
      ).withConverter(converter<Help>());

      getDocs(ref).then((snap) => {
        const docs = snap.docs.map((value) => value.data());

        // デフォルトのヘルプに追加ヘルプを組み合わせてpriorityでソート
        const helpDataArray = helpData.concat(docs).sort((first, second) => {
          if (first.priority < second.priority) return 1;
          return -1;
        });

        console.log("help", helpDataArray);

        setHelpDataArray(helpDataArray);
      });
    }
  }, [helpDataArray, course, orgId, uid, ver]);

  // ふりがな表記専用のuseStateの更新
  useEffect(() => {
    if (settingState && withFurigana === null) {
      const { withFurigana: withFuri } = settingState;
      setWithFurigana(withFuri);
    }
  }, [settingState, withFurigana]);

  return (
    <SettingStateContext.Provider
      value={{
        settingState,
        withFurigana,
        setWithFurigana,
        helpDataArray,
      }}
    >
      {children}
    </SettingStateContext.Provider>
  );
};

export const useSettingState = () =>
  useContext(SettingStateContext) as SettingContext;
