import GainItem from "./contents/GainItem";
import GainTicket from "./contents/GainTicket";
import InfoHelp from "./contents/InfoHelp";
import InfoItem from "./contents/InfoItem";
import InfoOthers from "./contents/InfoOthers";
import InfoSpot from "./contents/InfoSpot";
import InfoTicket from "./contents/InfoTicket";

const Modal: React.FC = () => {
  return (
    <>
      <InfoSpot />
      <InfoOthers />
      <GainItem />
      <GainTicket />
      <InfoItem />
      <InfoTicket />
      <InfoHelp />
    </>
  );
};

export default Modal;
