import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { buttonTexts } from "../../../../common/const/buttonTexts";
import { toastTexts } from "../../../../common/const/toastTexts";
import { surveyData } from "../../../../common/data/surveyData";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import { ModalDataState } from "../../../../common/recoil/modalAtoms";
import { UserAnswer } from "../../../../common/types/userTypes";
import ModalTemplate from "../../../../templates/ModalTemplate";
import CustomInput from "../../../shared/CustomInput";
import MultipleViewText from "../../../shared/MultipleViewText";
import { useModal } from "../../hooks/modalHooks";
import styles from "../ModalContents.module.scss";

const Survey: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("infoOthers");
  const setModalData = useSetRecoilState(ModalDataState);
  const { updateUserState } = useUserStateHooks();
  const router = useRouter();
  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const [withOthers, setWithOthers] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [input, setInput] = useState<string>("");
  const [answerArray, setAnswerArray] = useState<UserAnswer[]>([]);

  const {
    query: { ver, orgId, course, room },
  } = router;

  const filteredData = surveyData.filter((data) => {
    const { ver: verUrl, orgId: orgIdUrl, course: courseUrl } = data;

    if (ver === verUrl && orgId === orgIdUrl && course === courseUrl)
      return data;
  });

  const { surveyArray, surveyNameMV, index, surveyTextMVArray } =
    filteredData[0];

  // リセットでトップページに行った時の対処
  useEffect(() => {
    if (!room) {
      setIsVisible(false);
      setModalData(null);
    }
  }, [room, setIsVisible, isVisible, setModalData]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    },
    []
  );

  useEffect(() => {
    const subscription = methods.watch((value) => {
      if (
        value[`surveyIndex${step}`] === "その他" ||
        (Array.isArray(value[`surveyIndex${step}`]) &&
          value[`surveyIndex${step}`].includes("その他"))
      )
        return setWithOthers(true);
    });
    return () => subscription.unsubscribe();
  }, [filteredData, methods, step]);

  const onSubmit = async (data: { [key: string]: string | string[] }) => {
    setAnswerArray((s) => [
      ...s,
      {
        index: step,
        answer: data[`surveyIndex${step}`],
        others: withOthers ? input : "",
      },
    ]);
    if (step + 1 !== surveyArray.length) {
      setWithOthers(false);
      setInput("");
      return setStep((s) => s + 1);
    }

    const sendData = {
      surveyName: surveyNameMV?.kanji,
      index,
      answeredAt: new Date(),
      answerArray: [
        ...answerArray,
        {
          index: step,
          answer: data[`surveyIndex${step}`],
          others: withOthers ? input : "",
        },
      ],
    };

    updateUserState("answerSurvey", sendData);
    toast.success(
      <MultipleViewText text={toastTexts.回答ありがとうございましたMV} />
    );

    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <ModalTemplate bgCloseFunction={() => undefined}>
          <div className={styles.modalBody}>
            <div className={`${styles.modalInner} ${styles.survey}`}>
              <div className={styles.txt}>
                {surveyTextMVArray.map((textMV, index) => {
                  return (
                    <MultipleViewText
                      text={textMV}
                      key={textMV?.kanji + index}
                    />
                  );
                })}
              </div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <CustomInput
                    inputItem={surveyArray[step]}
                    surveyIndex={`surveyIndex${step}`}
                  />
                  {withOthers && (
                    <input
                      value={input}
                      onChange={onInputChange}
                      placeholder="その他"
                      className="mt-3 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  )}
                  <div className={styles.btnsWrap}>
                    <div className={styles.btnModalAgree}>
                      <button type="submit">
                        {step + 1 !== surveyArray.length ? (
                          <MultipleViewText text={buttonTexts.次へMV} />
                        ) : (
                          <MultipleViewText text={buttonTexts.送信MV} />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default Survey;
