import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import ModalTemplate from "../../../templates/ModalTemplate";
import MultipleViewText from "../../shared/MultipleViewText";
import { useModal } from "../hooks/modalHooks";
import styles from "./ModalContents.module.scss";
import { isSpot } from "../../../common/types/typeGuard";
import {
  GeolocateControlRefState,
  MapScreenState,
  RadarSpotState,
} from "../../../common/recoil/mapAtoms";
import { buttonTexts } from "../../../common/const/buttonTexts";
import { useRouter } from "next/router";
import { useUserQuestStateCheck } from "../../../common/hooks/userStateHooks";
import { ModalDataState } from "../../../common/recoil/modalAtoms";
import ImageComponent from "../../shared/ImageComponent";

const InfoSpot: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("infoSpot");
  const setModalData = useSetRecoilState(ModalDataState);

  return (
    <>
      {isVisible && (
        <ModalTemplate
          bgCloseFunction={() => {
            setModalData(null);
            setIsVisible(false);
          }}
        >
          <div className={styles.modalBody}>
            <ModeInfoSpot setIsVisible={setIsVisible} />
          </div>
          <div className={styles.btnModalClose}>
            <button
              type="button"
              onClick={() => {
                setModalData(null);
                setIsVisible(false);
              }}
            >
              <ImageComponent
                url="/images/btn/default/btn_close01"
                alt="閉じるボタン"
                width={36}
                height={36}
                className="object-cover"
                withBlur={false}
              />
            </button>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default InfoSpot;

// …………………………………………………………………………………………………………………………………………………………………………………………………………

interface Props {
  setIsVisible: SetterOrUpdater<boolean>;
}

const ModeInfoSpot: React.FC<Props> = ({ setIsVisible }) => {
  const [mapScreen, setMapScreen] = useRecoilState(MapScreenState);
  const [modalData, setModalData] = useRecoilState(ModalDataState);
  const setRadarSpotState = useSetRecoilState(RadarSpotState);
  const geolocateControlRefState = useRecoilValue(GeolocateControlRefState);
  const { userQuestStateCheck } = useUserQuestStateCheck();
  const router = useRouter();

  // modalDataがunionなので型判定
  if (!isSpot(modalData)) return null;

  const {
    content,
    spotNameMV,
    spotImageUrl,
    spotTextMVArray,
    spotAddressMV,
    spotWebsiteUrlArray,
  } = modalData;

  const userQuestState = userQuestStateCheck(content);

  return (
    <div className={styles.infoSpot}>
      <div className={styles.img}>
        {spotImageUrl && (
          <ImageComponent
            url={spotImageUrl}
            alt="スポット画像"
            className="object-cover"
            width={1200}
            height={800}
            extension="jpg"
          />
        )}
      </div>

      <div className={styles.spotName}>
        <MultipleViewText text={spotNameMV} />
      </div>
      <div className={styles.contentArea}>
        <div className={styles.contentInner}>
          <div className={styles.spotFeature}>
            {spotTextMVArray.map((spotTextMV, i) => {
              return (
                <div key={spotTextMV?.kanji + i}>
                  <div className={styles.nm}>{i + 1}</div>
                  <div className={styles.txt}>
                    <MultipleViewText text={spotTextMV} />
                  </div>
                </div>
              );
            })}
          </div>
          {spotAddressMV?.kanji && (
            <div className={styles.spotAdd}>
              <MultipleViewText text={spotAddressMV} />
            </div>
          )}
          {spotWebsiteUrlArray.length > 0 && (
            <div className={styles.spotUrl}>
              <a href={spotWebsiteUrlArray[0]} target="_blank" rel="noreferrer">
                Webサイト
              </a>
            </div>
          )}
        </div>
      </div>
      {mapScreen === "mainMap" && (
        <div className={styles.spotAction}>
          <button
            type="button"
            className={styles.btnChallenge}
            onClick={() => {
              // 到達済みか、およびクエストクリア済みかのチェック
              if (userQuestState) {
                setModalData(null);
                setIsVisible(false);
                return router.push(userQuestState);
              }

              setRadarSpotState(modalData);
              setMapScreen("radarMap");
              setModalData(null);
              geolocateControlRefState?.trigger();
              setIsVisible(false);
            }}
          >
            <MultipleViewText
              text={
                userQuestState.includes("prize")
                  ? buttonTexts.クリア済みMV
                  : buttonTexts?.挑戦するMV
              }
            />
          </button>
        </div>
      )}
    </div>
  );
};
