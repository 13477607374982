import { useRouter } from "next/router";
import { howToPlayData } from "../../../../common/data/howToPlayData";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

const HowToPlay: React.FC = () => {
  const router = useRouter();

  const {
    query: { ver, orgId, course, room },
  } = router;

  const howToPlay = howToPlayData.filter((data) => {
    const {
      ver: verUrl,
      orgId: orgIdUrl,
      course: courseUrl,
      room: roomUrl,
    } = data;

    if (
      ver === verUrl &&
      orgId === orgIdUrl &&
      course === courseUrl &&
      room === roomUrl
    )
      return data;
  });

  if (!howToPlay.length) return null;

  const { textMVArray } = howToPlay[0];

  return (
    <div className={styles.modalInner}>
      <div className={styles.howToPlay}>
        {textMVArray?.map((text, i) => {
          return (
            <div key={text?.kanji + i}>
              <MultipleViewText text={text} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowToPlay;
