import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import { Help } from "../../../../common/types/commonTypes";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  help: Help;
}

const GpsHeading: React.FC<Props> = ({ help }) => {
  const { helpTitleMV, helpTextMVArray } = help;
  const { updateUserState } = useUserStateHooks();

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={helpTitleMV} />
        </div>
      </div>
      <div>
        {helpTextMVArray.map((text, index) => {
          return (
            <div key={text?.kanji + index} className={styles.text}>
              <MultipleViewText text={text} />
            </div>
          );
        })}
      </div>
      <div className="mt-3 text-center">
        <button
          type="button"
          onClick={() => {
            updateUserState("changeWithHeading", "");
            window.location.reload();
          }}
          className="rounded bg-lime-500 p-1 font-bold"
        >
          対策する
        </button>
      </div>
    </div>
  );
};

export default GpsHeading;
