import { useEffect, useState } from "react";
import { UserGainedItem, UserState } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";
import { toast } from "react-hot-toast";
import { useUserState } from "../../../../common/context/userState.context";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { converter, firestore } from "../../../../common/lib/firebase";

interface Props {
  userGainedItem: UserGainedItem;
}

const Default: React.FC<Props> = ({ userGainedItem }) => {
  const { itemNameMV, itemImageUrl, itemTextMVArray, indexNumber } =
    userGainedItem;
  const [command, setCommand] = useState<string[]>([]);
  const { userState } = useUserState();

  const { uid, contentStateObject, course, solvedArray } = userState;

  useEffect(() => {
    // if (solvedArray.length < 5 && command.length === 3 && indexNumber !== 2) {
    //   toast.success("特に何も起こらなかった");
    //   setCommand([]);
    // }

    if (solvedArray.length > 5 && command.length === 3 && indexNumber === 2) {
      const ref = doc(
        firestore,
        `users/${uid}/userState/${course}`
      ).withConverter(converter<UserState>());

      const updateData = {
        contentStateObject: {
          ...contentStateObject,
          content7: {
            index: "content7",
            solvedAt: serverTimestamp(),
          },
        },
        solvedArray: arrayUnion("content7"),
        updatedAt: serverTimestamp(),
      };

      updateDoc(ref, updateData);

      toast.success(
        "最後の謎の画面に解答欄が現れた！3階にある巨大な扉を実際に開けよ！床に現れし暗号を「6715324の順」で解読し答えとして解答欄に入力せよ！",
        { duration: 10000 }
      );
      setCommand([]);
    }

    if (solvedArray.length < 6 && command.length === 1) {
      setCommand([]);
    }
  }, [
    command.length,
    contentStateObject,
    course,
    indexNumber,
    solvedArray.length,
    uid,
  ]);

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={itemNameMV} />
        </div>
      </div>
      <div className={`${styles.imgWrap} ${styles.item}`}>
        <div className={styles.imgInner}>
          <button
            type="button"
            onClick={() => setCommand((s) => [...s, "a"])}
            className={styles.img}
          >
            <ImageComponent
              url={itemImageUrl}
              alt={itemNameMV?.kanji}
              width={400}
              height={400}
            />
          </button>
        </div>
      </div>
      <div className={styles.text}>
        {itemTextMVArray.map((itemTextMV, index) => {
          if (index === 0) return <div key={index} />;

          return (
            <div key={itemTextMV?.kanji + index}>
              <MultipleViewText text={itemTextMV} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Default;
