import { useRouter } from "next/router";
import { answerData } from "../../../common/data/answerData";
import { questData } from "../../../common/data/questData";

const RiddleDocument: React.FC = () => {
  const router = useRouter();

  const {
    query: { course, room, content },
    asPath,
  } = router;

  const answer = answerData.filter((data) => {
    const { course: courseUrl, room: roomUrl, content: contentUrl } = data;

    if (courseUrl === course && roomUrl === room && contentUrl === content)
      return data;
  });

  const quest = questData.filter((data) => {
    const { course: courseUrl, room: roomUrl, content: contentUrl } = data;

    if (courseUrl === course && roomUrl === room && contentUrl === content)
      return data;
  });

  console.log(answer);

  const { questHintMVArray, explanation, answerArray } = answer[0] || {};

  const { triviaArray } = quest[0] || {};

  const render = "riddle";

  return (
    <div className="h-full w-full bg-white">
      {asPath.includes("quest") && (
        <div className="h-full w-full text-lg text-slate-800">
          <div className="mb-4 break-all border-b-2 border-slate-800 pb-2">
            <span className="text-lg font-bold">
              【ヒント】
              <span className="text-xs">※ヒントはヘルプの中にあります。</span>
            </span>
            <br />
            <div className="text-lg">
              <div
                dangerouslySetInnerHTML={{
                  __html: questHintMVArray[0].kanji,
                }}
                className={`furigana mt-1 whitespace-pre-wrap`}
              />
            </div>
            <br />
            {questHintMVArray.length > 1 && (
              <div className="mt-3">
                さらなるヒント→
                <div
                  dangerouslySetInnerHTML={{
                    __html: questHintMVArray[1].kanji,
                  }}
                  className={`furigana mt-1 whitespace-pre-wrap`}
                />
              </div>
            )}
          </div>
          <div className="mb-4 break-all border-b-2 border-slate-800 pb-2">
            <span className="text-lg font-bold">【答え】</span>
            <br />
            {answerArray[0]}
            <br />
            {answerArray.length > 1 && answerArray[1]}
          </div>
          <div className="mb-4 break-all border-b-2 border-slate-800 pb-2">
            <span className="text-lg font-bold">【解説】</span>
            <br />
            {explanation}
          </div>

          <div className="mb-4 break-all border-b-2 border-slate-800 pb-2">
            <span className="text-lg font-bold">
              【クリア画面で表示するトリビア】
            </span>
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: triviaArray[0].kanji,
              }}
              className={`furigana mt-1 whitespace-pre-wrap`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RiddleDocument;
