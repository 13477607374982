import {
  arrayUnion,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { converter, firestore } from "../lib/firebase";
import { useUserAuth } from "./userAuth.context";
import * as Sentry from "@sentry/nextjs";
import { useSettingState } from "./settingState.context";
import { UserProfile, UserState } from "../types/userTypes";
import { isString } from "../types/typeGuard";
import { useSession } from "next-auth/react";

interface ContextType {
  userState: UserState;
  setUserState: Dispatch<SetStateAction<UserState | null>>;
}

interface Props {
  children: ReactNode;
}

const UserStateContext = createContext<ContextType | null>(null);

export const UserStateProvider: React.FC<Props> = ({ children }) => {
  const { uid } = useUserAuth();
  const { settingState } = useSettingState();

  const [userState, setUserState] = useState<UserState | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [membershipState, setMembershipState] = useState<string>("");
  const router = useRouter();
  const { data: session } = useSession();

  const {
    query: { ver, orgId, course },
  } = router;

  useEffect(() => {
    if (uid) {
      const ref = doc(firestore, `users/${uid}`).withConverter(
        converter<UserProfile>()
      );

      getDoc(ref)
        .then((snap) => {
          if (snap.exists()) {
            console.log("userProfile", snap.data());
            setUserProfile(snap.data());
          }
        })
        .catch((error) => Sentry.captureException(error));
    }
  }, [uid]);

  useEffect(() => {
    // すでに参加済みの時はonsnapshotのフラグをtrueに
    if (!isString(course)) return;

    if (userProfile && userProfile.joinedArray.includes(course))
      return setIsJoined(true);
  }, [course, userProfile]);

  useEffect(() => {
    // 初挑戦の時はprofileとuserstateに挑戦状況を書き込み
    if (!isString(ver)) return;
    if (!isString(orgId)) return;
    if (!isString(course)) return;

    if (
      userProfile &&
      !userProfile.joinedArray.includes(course) &&
      !userState
    ) {
      const userProfileRef = doc(firestore, `users/${uid}`).withConverter(
        converter<UserProfile>()
      );

      const userProfileUpdate = {
        joinedArray: arrayUnion(course),
        updatedAt: serverTimestamp(),
      };

      const userStateRef = doc(
        firestore,
        `users/${uid}/userState/${course}`
      ).withConverter(converter<UserState>());

      const userStateSet = {
        uid,
        userRole: "user",
        ver,
        orgId,
        course,
        confirmedArray: [],
        courseCleared: false,
        contentStateObject: {},
        solvedArray: [],
        ticketArray: [],
        itemArray: [],
        surveyArray: [],
        withHeading: false,
        withReseted: false,
        withMembership: false,
        membershipType: "",
        membershipUid: "",
        membershipId: "",
        membershipPassword: "",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const batch = writeBatch(firestore);

      batch.update(userProfileRef, userProfileUpdate);
      batch.set(userStateRef, userStateSet);

      batch.commit();

      setIsJoined(true);
    }
  }, [course, orgId, uid, userProfile, userState, ver]);

  // セッションがあるときに、membershipUidとして登録
  useEffect(() => {
    if (session && uid) {
      const userId = session?.user?.name;

      if (isString(userId) && uid !== userId) {
        const ref = doc(
          firestore,
          `users/${userId}/userState/${course}`
        ).withConverter(converter<UserState>());

        const data = {
          membershipUid: uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        updateDoc(ref, data);

        console.log(`%csession userId ${uid} saved`, "color: red");
      }
    }
  }, [course, session, uid]);

  // membershipUidにuidを持つものがあるかを検索
  useEffect(() => {
    if (uid) {
      const userQuery = query(
        collectionGroup(firestore, "userState"),
        where("membershipUid", "==", uid)
      );

      getDocs(userQuery).then((snap) => {
        if (!snap.docs.length) return setMembershipState("none");

        const user = snap.docs[0].data();
        setMembershipState(user.uid);
      });
    }
  }, [uid]);

  useEffect(() => {
    let unsub: VoidFunction;

    if (isJoined && membershipState) {
      console.log("session", session);

      // sessionがある場合はsessionのuid(name)
      // membershipStateにuidが登録されているアカウントがある場合はmembershipState
      const userId = session?.user?.name
        ? session?.user?.name
        : membershipState !== "none"
        ? membershipState
        : uid;

      const userStateRef = doc(
        firestore,
        `users/${userId}/userState/${course}`
      ).withConverter(converter<UserState>());

      unsub = onSnapshot(userStateRef, (snap) => {
        if (snap.exists()) {
          console.log("userState", snap.data());
          Sentry.setUser({ uid: snap.data().uid });
          setUserState(snap.data());
        }
      });
    }

    return () => {
      if (unsub) return unsub();
    };
  }, [course, isJoined, membershipState, session, uid]);

  if (!userState || !settingState)
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-slate-900">
        <div className="p-9">
          <p className="animate-pulse text-center text-xl font-bold text-white">
            読み込み中...
          </p>
          <p className="mt-6 text-sm text-white">
            しばらく経っても画面が切り替わらない場合、読み込みに失敗している可能性があります。
            <br />
            恐れ入りますが画面の再読み込みをお願いいたします。
          </p>
        </div>
      </div>
    );

  return (
    <UserStateContext.Provider value={{ userState, setUserState }}>
      {children}
    </UserStateContext.Provider>
  );
};

export const useUserState = () => useContext(UserStateContext) as ContextType;
