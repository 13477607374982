import { GeolocateControlRef } from "react-map-gl";
import { atom } from "recoil";
import { AtomKeys } from "../const/recoilKeys";
import { GpsResult, Latlng } from "../types/roomTypes";
import { Spot } from "../types/spotTypes";

export const GeolocateControlRefState = atom<GeolocateControlRef | null>({
  key: AtomKeys.GEOLOCATE_CONTROL_REF_STATE,
  default: null,
});

export const CurrentPositionState = atom<Latlng | null>({
  key: AtomKeys.CURRENT_POSITION_STATE,
  default: null,
});

export const IsGpsAvailableState = atom<boolean>({
  key: AtomKeys.IS_GPS_AVAILABLE_STATE,
  default: false,
});

export const MapScreenState = atom<"mainMap" | "radarMap">({
  key: AtomKeys.MAP_SCREEN_STATE,
  default: "mainMap",
});

export const IsReachedState = atom<boolean>({
  key: AtomKeys.IS_REACHED_STATE,
  default: false,
});

export const RadarSpotState = atom<Spot | null>({
  key: AtomKeys.RADAR_SPOT_STATE,
  default: null,
});

export const GpsResultState = atom<GpsResult | null>({
  key: AtomKeys.GPS_RESULT_STATE,
  default: null,
});
