import { Help } from "../../../../common/types/commonTypes";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  help: Help;
}

const Inquiry: React.FC<Props> = ({ help }) => {
  const { helpDataArray, helpTextMVArray, helpTitleMV } = help;

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={helpTitleMV} />
        </div>
      </div>
      <div className={styles.text}>
        {helpTextMVArray.map((textMV, index) => {
          return (
            <div key={textMV?.kanji + index}>
              <MultipleViewText text={textMV} />
            </div>
          );
        })}
      </div>
      <div className={styles.text}>
        {helpDataArray.length > 0 && helpDataArray[0] !== "none" && (
          <div>
            Email:
            <a
              href={`mailto:${helpDataArray[0]}?subject=%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&body=%EF%BC%88%E3%81%94%E5%8F%82%E5%8A%A0%E3%81%AE%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%90%8D%E3%81%A8%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%E3%82%92%E3%81%94%E8%A8%98%E5%85%A5%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%EF%BC%89`}
            >
              {helpDataArray[0]}
            </a>
          </div>
        )}
        {helpDataArray.length > 1 && helpDataArray[1] !== "none" && (
          <div>Phone:{helpDataArray[1]}</div>
        )}

        {helpDataArray.length > 2 && helpDataArray[2] !== "none" && (
          <div>
            Web:
            <a href={helpDataArray[2]} target="_blank" rel="noreferrer">
              {helpDataArray[2]}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Inquiry;
