import DOMPurify from "isomorphic-dompurify";
import { memo, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useSettingState } from "../../common/context/settingState.context";
import { GpsResultState } from "../../common/recoil/mapAtoms";
import { TextMV } from "../../common/types/commonTypes";

interface Props {
  text: TextMV;
  markArray?: string[];
  replaceArray?: TextMV[];
  message?: boolean;
}

const MultipleViewText: React.FC<Props> = ({
  text,
  markArray,
  replaceArray,
  message,
}) => {
  const [displayText, setDisplayText] = useState<string>("");
  const { withFurigana } = useSettingState();
  const sanitizer = DOMPurify.sanitize;
  const [typingText, setTypingText] = useState<string>("");
  const gpsResultState = useRecoilValue(GpsResultState);

  useEffect(() => {
    setTypingText("");
  }, [text]);

  // テキストの置換処理
  useEffect(() => {
    if (!text) return;

    const { furigana, kanji } = text;

    let data = withFurigana && furigana ? furigana : kanji;

    // テキスト内の文字を入れ替える場合の処理
    if (markArray && replaceArray && markArray.length === replaceArray.length) {
      markArray.forEach((mark, index) => {
        const replaceData =
          withFurigana && furigana
            ? replaceArray[index].furigana
            : replaceArray[index].kanji;

        data = data.replace(mark, replaceData);
      });
    }

    setDisplayText(data);
  }, [markArray, replaceArray, text, withFurigana]);

  // メッセージのみ、タイピング処理
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (message && !withFurigana && !gpsResultState) {
      timeout = setTimeout(() => {
        setTypingText(displayText.slice(0, typingText.length + 1));
      }, 50);
    } else {
      setTypingText(displayText);
    }
    return () => clearTimeout(timeout);
  }, [displayText, typingText, message, withFurigana, gpsResultState]);

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            message && !withFurigana ? typingText : displayText
          ),
        }}
        className={`${withFurigana ? "furigana" : ""} whitespace-pre-wrap`}
      />
    </>
  );
};

export default memo(MultipleViewText);
