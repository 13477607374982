import "../common/styles/globals.scss";
import "../common/styles/animations.scss";
import "../common/styles/override.scss";
import type { AppPropsWithLayout } from "next/app";
import MainFlameTemplate from "../templates/MainFlameTemplate";
import { RecoilRoot } from "recoil";
import { UserAuthProvider } from "../common/context/userAuth.context";
import { UserStateProvider } from "../common/context/userState.context";
import { SettingStateProvider } from "../common/context/settingState.context";
import Modal from "../components/modal/Modal";
import { Toaster } from "react-hot-toast";
import DisplayAuthCheck from "../components/shared/DisplayAuthCheck";
import { SessionProvider } from "next-auth/react";
import { Session } from "next-auth";

const MyApp = ({
  Component,
  pageProps,
}: AppPropsWithLayout<{ session: Session }>) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SessionProvider session={pageProps.session}>
      <UserAuthProvider>
        <SettingStateProvider>
          <UserStateProvider>
            <MainFlameTemplate>
              <RecoilRoot>
                <DisplayAuthCheck />
                <Toaster />
                {getLayout(<Component {...pageProps} />)}
                <Modal />
              </RecoilRoot>
            </MainFlameTemplate>
          </UserStateProvider>
        </SettingStateProvider>
      </UserAuthProvider>
    </SessionProvider>
  );
};

export default MyApp;
