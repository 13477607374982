import { useRecoilValue, useSetRecoilState } from "recoil";
import { titleTexts } from "../../../common/const/titleTexts";
import { ModalDataState } from "../../../common/recoil/modalAtoms";
import { isTicket } from "../../../common/types/typeGuard";
import ModalTemplate from "../../../templates/ModalTemplate";
import ImageComponent from "../../shared/ImageComponent";
import MultipleViewText from "../../shared/MultipleViewText";
import { useModal } from "../hooks/modalHooks";
import styles from "./ModalContents.module.scss";

const GainTicket: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("gainTicket");
  const setModalData = useSetRecoilState(ModalDataState);

  return (
    <>
      {isVisible && (
        <ModalTemplate
          bgCloseFunction={() => {
            setModalData(null);
            setIsVisible(false);
          }}
        >
          <div className={styles.modalBody}>
            <ModalGainTicket />
          </div>
          <div className={styles.btnModalClose}>
            <button
              type="button"
              onClick={() => {
                setModalData(null);
                setIsVisible(false);
              }}
            >
              <ImageComponent
                url="/images/btn/default/btn_close01"
                alt="閉じるボタン"
                width={36}
                height={36}
                className="object-cover"
                withBlur={false}
              />
            </button>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default GainTicket;

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const ModalGainTicket: React.FC = () => {
  const modalData = useRecoilValue(ModalDataState);

  // modalDataがunionなので型判定
  if (!isTicket(modalData)) return null;

  const { ticketNameMV, ticketImageUrl, ticketTextMVArray } = modalData;

  // [ ] 画像の高さについて応急処置中
  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.message}>
        <MultipleViewText text={titleTexts?.チケットを手に入れたMV} />
      </div>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={ticketNameMV} />
        </div>
      </div>
      <div className={`${styles.imgWrap} before:pt-[65%]`}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={ticketImageUrl}
              alt={ticketNameMV?.kanji}
              width={400}
              height={200}
            />
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <MultipleViewText text={ticketTextMVArray[0]} />
      </div>
    </div>
  );
};
