import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSettingState } from "../../../common/context/settingState.context";
import { useUserState } from "../../../common/context/userState.context";
import { isSettingState, isString } from "../../../common/types/typeGuard";

// リダイレクトのパス判定用
export function useCheckPath() {
  const { userState } = useUserState();
  const { settingState } = useSettingState();
  const router = useRouter();

  const { contentStateObject, solvedArray } = userState;
  const {
    asPath,
    query: { ver, orgId, course, room, content },
  } = router;

  const checkPath = useCallback(
    (authCheckType: string, type: string): string => {
      if (!authCheckType) return "";
      if (!isSettingState(settingState)) return "";

      const { requireSolve } = settingState;

      // // 利用規約承認が必要&&承認してない時
      // if (withApproval && !confirmedArray.includes("approval"))
      //   return `/${ver}/${orgId}/${course}`;

      // ルームページ……………………………………………………………………………………………………
      if (authCheckType === "room1") {
        // クエストページの時の処理（prize用のauthCheckTypeが無いので）
        if (type === "button") {
          return `/${ver}/${orgId}/${course}`;
        }
      }

      // QRマップページ……………………………………………………………………………………………………
      if (authCheckType === "QRcodeMap") {
        if (!isString(content)) return "";
        const { reachedAt } = contentStateObject[content] || {};

        // クエストページの時の処理（prize用のauthCheckTypeが無いので）
        if (type === "redirect") {
          if (reachedAt) {
            return `/${ver}/${orgId}/${course}/${room}/${content}/quest`;
          }
        }
        if (type === "button") {
          return `/${ver}/${orgId}/${course}/${room}`;
        }
      }

      // 最後の謎のQRマップページ……………………………………………………………………………………………………
      if (authCheckType === "QRcodeMapFinal") {
        if (!isString(content)) return "";

        const { reachedAt } = contentStateObject[content] || {};

        // クエストページの時の処理（prize用のauthCheckTypeが無いので）
        if (type === "redirect") {
          if (solvedArray.length < requireSolve) {
            return `/${ver}/${orgId}/${course}/${room}`;
          }

          if (reachedAt) {
            return `/${ver}/${orgId}/${course}/${room}/${content}/quest`;
          }
        }
        if (type === "button") {
          return `/${ver}/${orgId}/${course}/${room}`;
        }
      }

      // クエストページとプライズページ……………………………………………………………………………………………………
      if (authCheckType === "questWithSpot") {
        if (!isString(content)) return "";
        const { reachedAt, solvedAt } = contentStateObject[content] || {};

        // クエストページの時の処理（prize用のauthCheckTypeが無いので）
        if (asPath.includes("quest")) {
          if (type === "redirect") {
            if (!reachedAt) {
              return `/${ver}/${orgId}/${course}/${room}`;
            }
            if (reachedAt && solvedAt) {
              return `/${ver}/${orgId}/${course}/${room}/${content}/prize`;
            }
          }
          if (type === "button") {
            return `/${ver}/${orgId}/${course}/${room}`;
          }
        }
        // プライズページの時の処理（prize用のauthCheckTypeが無いので）
        if (asPath.includes("prize")) {
          if (type === "redirect") {
            if (!reachedAt) {
              return `/${ver}/${orgId}/${course}/${room}`;
            }

            if (reachedAt && !solvedAt) {
              return `/${ver}/${orgId}/${course}/${room}/${content}/quest`;
            }
          }
          if (type === "button") {
            return `/${ver}/${orgId}/${course}/${room}`;
          }
        }
      }

      if (authCheckType === "questWithoutSpot") {
        if (!isString(content)) return "";
        const { solvedAt } = contentStateObject[content] || {};

        // クエストページの時の処理（prize用のauthCheckTypeが無いので）
        if (asPath.includes("quest")) {
          if (type === "redirect") {
            if (solvedAt) {
              return `/${ver}/${orgId}/${course}/${room}/${content}/prize`;
            }
          }
          if (type === "button") {
            return `/${ver}/${orgId}/${course}/${room}`;
          }
        }
        // プライズページの時の処理（prize用のauthCheckTypeが無いので）
        if (asPath.includes("prize")) {
          if (type === "redirect") {
            if (!solvedAt) {
              return `/${ver}/${orgId}/${course}/${room}/${content}/quest`;
            }
          }
          if (type === "button") {
            return `/${ver}/${orgId}/${course}/${room}`;
          }
        }
      }

      // 最後のクエストページとプライズページ……………………………………………………………………………………………………
      if (authCheckType === "finalQuest") {
        if (!isString(content)) return "";
        const { solvedAt } = contentStateObject[content] || {};

        // クエストページの時の処理（prize用のauthCheckTypeが無いので）
        if (asPath.includes("quest")) {
          if (type === "redirect") {
            if (solvedArray.length < requireSolve) {
              return `/${ver}/${orgId}/${course}/${room}`;
            }

            if (solvedAt) {
              return `/${ver}/${orgId}/${course}/${room}/${content}/prize`;
            }
          }
          if (type === "button") {
            return `/${ver}/${orgId}/${course}/${room}`;
          }
        }
        // プライズページの時の処理（prize用のauthCheckTypeが無いので）
        if (asPath.includes("prize")) {
          if (type === "redirect") {
            if (solvedArray.length < requireSolve) {
              return `/${ver}/${orgId}/${course}/${room}`;
            }

            if (!solvedAt) {
              return `/${ver}/${orgId}/${course}/${room}/${content}/quest`;
            }
          }
          if (type === "button") {
            return `/${ver}/${orgId}/${course}/${room}`;
          }
        }
      }

      return "";
    },
    [
      asPath,
      content,
      contentStateObject,
      course,
      orgId,
      room,
      settingState,
      solvedArray.length,
      ver,
    ]
  );

  return {
    checkPath,
  };
}
