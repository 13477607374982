import { Popover } from "@headlessui/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { buttonTexts } from "../../../../common/const/buttonTexts";
import { divTexts } from "../../../../common/const/divTexts";
import { auth, firestore } from "../../../../common/lib/firebase";
import { Help } from "../../../../common/types/commonTypes";
import MultipleViewText from "../../../shared/MultipleViewText";
import PopoverPanel from "../../../shared/PopoverPanel";
import styles from "../ModalContents.module.scss";
import { RiLockPasswordFill } from "react-icons/ri";
import { useSetRecoilState } from "recoil";
import { DisplayMenuState } from "../../../../common/recoil/menuAtoms";
import { useModal } from "../../hooks/modalHooks";
import { useRouter } from "next/router";

interface Props {
  help: Help;
}

const RestoreMembership: React.FC<Props> = ({ help }) => {
  const setDisplayMenu = useSetRecoilState(DisplayMenuState);
  const [, setIsVisible] = useModal("infoHelp");
  const [input, setInput] = useState<string>("");
  const router = useRouter();

  const { helpTitleMV, helpTextMVArray } = help;
  const {
    query: { ver, orgId, course, room },
  } = router;

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    },
    []
  );

  const membershipCheck = async () => {
    const userQuery = query(
      collectionGroup(firestore, "userState"),
      where("membershipId", "==", input)
    );

    const snapShot = await getDocs(userQuery);

    if (!snapShot.docs.length)
      return toast.error(
        "このメールアドレスで登録されたアカウントはありません。"
      );

    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        toast.success("復元に成功しました。");
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        const user = result.user;

        console.log(25, result);
        console.log(user);
      })
      .catch((error) => {
        // [ ] エラー種別でトースト用意
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);

        console.log(error);
      });
  };

  // [ ] ボタンデザイン調整
  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={helpTitleMV} />
        </div>
      </div>

      {helpTextMVArray.map((text, index) => {
        return (
          <div key={text?.kanji + index} className={styles.text}>
            <MultipleViewText text={text} />
          </div>
        );
      })}
      {/* <div className={styles.btnUseWrap}>
        <PopoverPanel
          bgColor=""
          buttonText={buttonTexts.GoogleアカウントでMV}
          className={`${styles.btnGoogle}`}
        >
          <div className={styles.popupBox}>
            <div>
              <MultipleViewText
                text={divTexts.登録時に使用したメールアドレスをMV}
              />
            </div>
            <div>
              <input
                value={input}
                onChange={onInputChange}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
              />
            </div>
            <div className={styles.btns}>
              <button
                type="button"
                onClick={() => membershipCheck()}
                className={`${styles.btnNext} heartbeat`}
                onTouchStart={() => {
                  return {};
                }}
              >
                <MultipleViewText text={buttonTexts.送信MV} />
              </button>
              <Popover.Button className="">
                <MultipleViewText text={buttonTexts.閉じるMV} />
              </Popover.Button>
            </div>
          </div>
        </PopoverPanel>
      </div> */}
      <div className="mt-3 px-[1em]">
        <button
          type="button"
          onClick={() => {
            setDisplayMenu("");
            setIsVisible(false);
            router.push({
              pathname: `/${ver}/${orgId}/${course}/auth/signin`,
              query: { room },
            });
          }}
          className={`heartbeat ${styles.btnPass}`}
          onTouchStart={() => {
            return {};
          }}
        >
          <RiLockPasswordFill />
          <span className="ml-1">ID/パスワードで復元</span>
        </button>
      </div>
    </div>
  );
};

export default RestoreMembership;
