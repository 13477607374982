import { Ticket } from "../types/modalTypes";

export const ticketData: Ticket[] = [
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "everywhere",
    type: "anytime",
    index: "ticket1",
    indexNumber: 1,
    priority: 50,
    distributeStock: 0,
    usableStock: 0,
    ticketNameMV: {
      kanji: "クリアの証チケット",
      furigana: "",
    },
    ticketImageUrl: "/images/ticket/nazotoki/ticket1",
    ticketTextMVArray: [
      {
        kanji:
          "クリアの証です。JRタワーショッピングチケット1万円分の抽選に応募できるよ！メニューのチケットから確認してね。",
        furigana: "",
      },
    ],
    displayConditionArray: [
      {
        url: "",
        type: "includes",
        direction: "true",
        item: "solvedArray",
        targetValueArray: ["final"],
        targetNumber: 0,
      },
    ],
  },
];
