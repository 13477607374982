import { FieldValues, useFormContext } from "react-hook-form";
import { InputItem } from "../../common/types/modalTypes";
import styles from "./CustomInput.module.scss";
import MultipleViewText from "./MultipleViewText";

interface Props {
  inputItem: InputItem;
  surveyIndex: string;
}

const CustomInput: React.FC<Props> = ({ inputItem, surveyIndex }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FieldValues>();

  const { model, nameMV, inputType, validation, choiceMVArray } = inputItem;

  const renderInput = () => {
    const texts = {
      回答必須MV: {
        kanji: "回答必須",
        furigana:
          '<ruby data-ruby="かいとう">回答</ruby><ruby data-ruby="ひっす">必須</ruby>',
      },
    };

    const { 回答必須MV } = texts;

    const question = validation?.required
      ? {
          kanji:
            nameMV.kanji +
            `<span class='text-red-500 ml-1'>※${回答必須MV.kanji}</span>`,
          furigana:
            nameMV.furigana +
            `<span class='text-red-500 ml-1'>※${回答必須MV.furigana}</span>`,
        }
      : nameMV;

    if (model === "text") {
      return (
        <>
          <div className="relative mt-3 h-full">
            <label htmlFor={surveyIndex} className={styles.leadTxt}>
              <div className="text-left font-bold">
                <MultipleViewText text={question} />
              </div>
            </label>
            <div className="mt-1">
              <input
                type={inputType}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                {...register(surveyIndex, validation)}
              />
            </div>
            {errors[surveyIndex] && (
              <span className="text-snRed text-sm font-bold">
                {errors[surveyIndex]?.message as unknown as string}
              </span>
            )}
          </div>
        </>
      );
    }

    if (model === "checkbox") {
      return (
        <>
          <div className="relative mt-3 h-full">
            <label htmlFor={surveyIndex} className={styles.leadTxt}>
              <div className="text-left font-bold">
                <MultipleViewText text={question} />
              </div>
            </label>
            <div className="max-h-[250px] overflow-x-hidden overflow-y-scroll text-left">
              {choiceMVArray.map((choiceMV, cIndex) => {
                return (
                  <div
                    className={styles.inputWrap}
                    key={choiceMV?.kanji + cIndex}
                  >
                    <input
                      id={choiceMV?.kanji + cIndex + surveyIndex}
                      type="checkbox"
                      value={choiceMV?.kanji}
                      className={styles.check}
                      {...register(surveyIndex, validation)}
                    />
                    <label
                      htmlFor={choiceMV?.kanji + cIndex}
                      className={styles.inputTxt}
                    >
                      <MultipleViewText text={choiceMV} />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {errors[surveyIndex] && (
            <span className="text-sm font-bold text-red-500">
              {errors[surveyIndex]?.message as unknown as string}
            </span>
          )}
        </>
      );
    }

    if (model === "radio") {
      return (
        <>
          <div className="relative mt-3 h-full">
            <label htmlFor={surveyIndex} className={styles.leadTxt}>
              <div className="text-left font-bold">
                <MultipleViewText text={question} />
              </div>
            </label>
            <div className="text-lef max-h-[250px] overflow-x-hidden overflow-y-scroll">
              {choiceMVArray.map((choiceMV, cIndex) => {
                return (
                  <div
                    className={styles.inputWrap}
                    key={choiceMV?.kanji + cIndex}
                  >
                    <input
                      id={choiceMV?.kanji + cIndex + surveyIndex}
                      type="radio"
                      value={choiceMV?.kanji}
                      className={styles.radio}
                      {...register(surveyIndex, validation)}
                    />
                    <label
                      htmlFor={choiceMV?.kanji + cIndex}
                      className={styles.inputTxt}
                    >
                      <MultipleViewText text={choiceMV} />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {errors[surveyIndex] && (
            <span className="text-sm font-bold text-red-500">
              {errors[surveyIndex]?.message as unknown as string}
            </span>
          )}
        </>
      );
    }
  };

  return <>{renderInput()}</>;
};

export default CustomInput;
