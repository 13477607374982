import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth, converter, firestore } from "../lib/firebase";
import * as Sentry from "@sentry/nextjs";
import { UserProfile } from "../types/userTypes";

interface ContextType {
  uid: string;
}

interface Props {
  children: ReactNode;
}

const UserAuthContext = createContext<ContextType | null>(null);

export const UserAuthProvider: React.FC<Props> = ({ children }) => {
  const [uid, setUid] = useState<string>("");

  useEffect(() => {
    const authUnsub = onAuthStateChanged(auth, async (authObj) => {
      if (authObj) {
        setUid(authObj.uid);
      } else {
        try {
          const userCredential = await signInAnonymously(auth);

          const { uid } = userCredential.user;
          const ref = doc(firestore, `users/${uid}`).withConverter(
            converter<UserProfile>()
          );

          const data = {
            uid,
            userRole: "user",
            joinedArray: [],
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          };

          await setDoc(ref, data).then(() => setUid(uid));
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    });

    return () => {
      authUnsub();
    };
  }, []);

  return (
    <UserAuthContext.Provider value={{ uid }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext) as ContextType;
