import { useRouter } from "next/router";
import { divTexts } from "../../../../common/const/divTexts";
import { privacyPolicyData } from "../../../../common/data/privacyPolicy";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

const PrivacyPolicy: React.FC = () => {
  const router = useRouter();
  const {
    query: { ver, orgId, course },
  } = router;

  const isSmartphone = !navigator.userAgent.match(
    /(iPhone|iPod|Android.*Mobile)/i
  );

  const privacyPolicy = privacyPolicyData.filter((data) => {
    const { ver: verUrl, orgId: orgIdUrl, course: courseUrl } = data;

    if (ver === verUrl && orgId === orgIdUrl && course === courseUrl)
      return data;
  });

  if (!privacyPolicy.length) return null;

  const { textMVArray } = privacyPolicy[0];

  return (
    <div className={`${styles.modalInner}`}>
      <div className={styles.precaution}>
        {isSmartphone && (
          <div className="mb-3 font-bold text-red-500">
            <MultipleViewText text={divTexts?.当コンテンツはMV} />
          </div>
        )}
        {textMVArray?.map((text, i) => {
          return (
            <div key={text?.kanji + i} className="mb-1">
              <MultipleViewText text={text} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
