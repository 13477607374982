import { atom, atomFamily } from "recoil";
import { AtomKeys } from "../const/recoilKeys";
import { Help } from "../types/commonTypes";
import { Item, Ticket } from "../types/modalTypes";
import { Spot } from "../types/spotTypes";
import { UserGainedItem, UserGainedTicket } from "../types/userTypes";

export type ModalType =
  | "infoOthers"
  | "infoSpot"
  | "gainItem"
  | "gainTicket"
  | "infoItem"
  | "infoTicket"
  | "infoHelp"
  | "infoError"
  | "survey";
export const ModalVisibilityState = atomFamily({
  key: AtomKeys.MODAL_VISIBILITY_STATE,
  default: false,
});

export const ModalDataState = atom<
  | Spot
  | Item
  | Ticket
  | UserGainedItem
  | UserGainedTicket
  | Help
  | string
  | null
>({
  key: AtomKeys.MODAL_DATA_STATE,
  default: null,
});
