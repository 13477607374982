import { Quest } from "../types/questTypes";

export const questData: Quest[] = [
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content1",
    index: "quest1",
    type: "riddle",
    questType: "multipleImageT_leftRightSwitchB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "地下1階",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: ["left", "right", "right", "left"],
    questImageUrlArray: [
      "/images/quest/nazotoki/content1_1",
      "/images/quest/nazotoki/content1_2",
      "/images/quest/nazotoki/content1_3",
      "/images/quest/nazotoki/content1_4",
      "/images/quest/nazotoki/content1_5",
    ],
    triviaArray: [
      {
        kanji:
          "東コンコースより東側が「STELLAR PLACE EAST」、西コンコースと東コンコースの間が「STELLAR PLACE CENTER」です。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content2",
    index: "quest2",
    type: "riddle",
    questType: "bigImageT_textInputB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "1階",
      furigana: "",
    },
    questTextMVArray: [
      {
        kanji:
          "この画像は縮小できない。SからGまで\n「外壁にある大時計にある模様だけ」通って進め！",
        furigana: "",
      },
    ],
    questStringArray: [],
    questImageUrlArray: ["/images/quest/nazotoki/content2_1"],
    triviaArray: [
      {
        kanji:
          "外壁の正面にある「星の大時計」は、大時計の外壁下部に貼られたソーラーパネルを動力源にしています。",
        furigana: "",
      },
    ],
    messageArray: [],
  },
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content3",
    index: "quest3",
    type: "riddle",
    questType: "singleImageT_textInputB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "4階",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: [],
    questImageUrlArray: ["/images/quest/nazotoki/content3_1"],
    triviaArray: [
      {
        kanji:
          "ステラ（stellar）には「星の」「輝かしい」などの意味があります。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content4",
    index: "quest4",
    type: "riddle",
    questType: "selectWordT_textInputB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "5階",
      furigana: "",
    },
    questTextMVArray: [
      {
        kanji:
          "20年前の「2003年」にあった出来事を選び\nタップして暗号を解読せよ！",
        furigana: "",
      },
      {
        kanji: "JRタワーが開業",
        furigana: "",
      },
      {
        kanji: "消費税が5%→8%に",
        furigana: "",
      },
      {
        kanji: "北海道新幹線が開業",
        furigana: "",
      },
      {
        kanji: "六本木ヒルズオープン",
        furigana: "",
      },
      {
        kanji: "「はやぶさ」打ち上げ",
        furigana: "",
      },
      {
        kanji: "富士山が世界遺産に",
        furigana: "",
      },
      {
        kanji: "太字は読まない",
        furigana: "",
      },
      {
        kanji: "黒文字は読まない",
        furigana: "",
      },
      {
        kanji: "細字は読まない",
        furigana: "",
      },
      {
        kanji: "赤文字は読まない",
        furigana: "",
      },
      {
        kanji: "ひらがなは読まない",
        furigana: "",
      },
      {
        kanji: "カタカナは読まない",
        furigana: "",
      },
    ],
    questStringArray: [],
    questImageUrlArray: ["/images/quest/nazotoki/content4_1"],
    triviaArray: [
      {
        kanji: "残りの3つは全て2010年より後の出来事でした。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "content5",
    index: "quest5",
    type: "riddle",
    questType: "animationT_textInputB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "6階",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: ["5000", "stellar"],
    questImageUrlArray: [
      "/images/quest/nazotoki/content5_1",
      "/images/quest/nazotoki/content5_2",
      "/images/quest/nazotoki/content5_3",
      "/images/quest/nazotoki/content5_4",
    ],
    triviaArray: [
      {
        kanji:
          "ステラプレイスのセンター9階には、空と緑に囲まれて札幌の街並みを一望出来る屋上広場「そらのひろば ｽﾃﾗ9」があります。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    room: "room1",
    content: "final",
    index: "final",
    type: "riddle",
    questType: "stellarFinalT_stellarFinalB",
    prizeType: "final",
    authCheckType: "finalQuest",
    answerType: "katakana",
    spotNameMV: {
      kanji: "3階 最後",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: [],
    questImageUrlArray: [
      "/images/quest/nazotoki/final1_1",
      "/images/quest/nazotoki/final1_2",
    ],
    triviaArray: [
      {
        kanji: "",
        furigana: "",
      },
    ],
    messageArray: [],
  },
];
