import Image from "next/image";
import { useSettingState } from "../../common/context/settingState.context";

interface Props {
  url: string;
  width: number;
  height: number;
  alt: string;
  loading?: "eager" | "lazy";
  withBlur?: boolean;
  furiganaSwitch?: boolean;
  extension?: string;
  className?: string;
}

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

const ImageComponent: React.FC<Props> = ({
  url,
  width,
  height,
  alt,
  loading = "lazy",
  withBlur = true,
  furiganaSwitch = false,
  extension = "png",
  className = "object-contain",
}) => {
  const { withFurigana } = useSettingState();

  return (
    <Image
      src={`${url}${
        furiganaSwitch && withFurigana ? "_furi" : ""
      }.${extension}`}
      alt={alt}
      width={width}
      height={height}
      loading={loading}
      placeholder={withBlur ? "blur" : undefined}
      blurDataURL={
        withBlur
          ? `${url}${
              furiganaSwitch && withFurigana ? "_furi" : ""
            }.${extension}?auto=format,compress&q=1&blur=500&w=2`
          : undefined
      }
      // blurDataURL={
      //   withBlur
      //     ? `data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`
      //     : undefined
      // }
      className={className}
    />
  );
};

export default ImageComponent;
