// Atomを管理するキー
export enum AtomKeys {
  // modal…………………………………………………
  MODAL_VISIBILITY_STATE = "modalVisibilityState",
  MODAL_DATA_STATE = "modalDataState",
  // menu…………………………………………………
  DISPLAY_MENU_STATE = "displayMenuState",
  // map…………………………………………………
  GEOLOCATE_CONTROL_REF_STATE = "geolocateControlRefState",
  CURRENT_POSITION_STATE = "currentPositionState",
  IS_GPS_AVAILABLE_STATE = "isGpsAvailableState",
  MAP_SCREEN_STATE = "mapScreenState",
  IS_REACHED_STATE = "isReachedState",
  RADAR_SPOT_STATE = "radarSpotState",
  GPS_RESULT_STATE = "gpsResultStateState",
  // …………………………………………………
  AUTH_CHECK_TYPE_STATE = "authCheckTypeState",
  // message…………………………………………………
  DEFAULT_MESSAGE_STATE = "defaultMessageState",
  PRIORITY_MESSAGE_STATE = "priorityMessageState",
  // breadcrumb…………………………………………………
  BREADCRUMB_TEXT_STATE = "breadcrumbTextStateState",
  // quest…………………………………………………
  QUEST_COMMAND_ARRAY_STATE = "questCommandArrayState",
}

// Selectorを管理するキー
export enum SelectorKeys {
  TICKET_COUNT_SELECTOR = "ticketCountSelector",
}
