import { ReactNode } from "react";
import styles from "./ModalTemplate.module.scss";

interface Props {
  children: ReactNode;
  bgCloseFunction: VoidFunction;
}

const ModalTemplate: React.FC<Props> = ({ children, bgCloseFunction }) => {
  return (
    <div className={styles.modalBg}>
      <button
        type="button"
        className="absolute top-0 h-full w-full"
        onClick={() => bgCloseFunction()}
      />
      <div className={styles.modalContainer}>{children}</div>
    </div>
  );
};

export default ModalTemplate;
