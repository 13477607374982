import { Popover, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { TextMV } from "../../common/types/commonTypes";
import MultipleViewText from "./MultipleViewText";

interface Props {
  children: ReactNode;
  bgColor: string;
  buttonText: TextMV;
  buttonTextColor?: string;
  className: string;
}

const PopoverPanel: React.FC<Props> = ({
  children,
  bgColor,
  buttonText,
  buttonTextColor = "text-white",
  className,
}) => {
  return (
    <Popover className="relative">
      {({ open }: { open: boolean }) => (
        <>
          <Popover.Button
            className={`
                    ${
                      open ? "" : "text-opacity-90"
                    } ${bgColor} ${className} ${buttonTextColor}
                    group inline-flex items-center justify-center rounded-md px-3 py-2 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span>
              <MultipleViewText text={buttonText} />
            </span>
            <IoChevronDownCircleOutline
              className={`${open ? "" : "text-opacity-70"}
                      ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80 ${buttonTextColor}`}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute -bottom-5 z-50 w-full transform">
              <div className="w-full overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                {children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PopoverPanel;
