import { Precaution } from "../types/modalTypes";

export const precautionData: Precaution[] = [
  {
    ver: "v1",
    orgId: "stellarplace",
    course: "nazotoki",
    type: "default",
    textMVArray: [
      {
        kanji: "以下の利用規約・注意事項に同意のうえご参加ください。",
        furigana:
          '<ruby data-ruby="いか">以下</ruby>の<ruby data-ruby="りよう">利用</ruby><ruby data-ruby="きやく">規約</ruby>・<ruby data-ruby="ちゅうい">注意</ruby><ruby data-ruby="じこう">事項</ruby>をご<ruby data-ruby="かくにん">確認</ruby>ください。',
      },
      {
        kanji: "",
        furigana: "",
      },
      {
        kanji:
          "・参加費は無料ですが、インターネット接続にあたっての通信料はお客様負担となります。ご理解の上ご参加ください。",
        furigana:
          '・<ruby data-ruby="さんか">参加</ruby><ruby data-ruby="ひ">費</ruby>は<ruby data-ruby="むりょう">無料</ruby>ですが、インターネット<ruby data-ruby="せつぞく">接続</ruby>にあたっての<ruby data-ruby="つうしん">通信</ruby><ruby data-ruby="りょう">料</ruby>、<ruby data-ruby="こうつう">交通</ruby><ruby data-ruby="ひ">費</ruby>はお<ruby data-ruby="きゃくさま">客様</ruby><ruby data-ruby="ふたん">負担</ruby>となります。ご<ruby data-ruby="りかい">理解</ruby>の<ruby data-ruby="うえ">上</ruby>ご<ruby data-ruby="さんか">参加</ruby>ください。',
      },
      {
        kanji: "・1台のスマートフォンから1回に限り参加できます。",
        furigana:
          '・1<ruby data-ruby="だい">台</ruby>のスマートフォンから1<ruby data-ruby="かい">回</ruby>に<ruby data-ruby="かぎ">限</ruby>り<ruby data-ruby="さんか">参加</ruby>できます。',
      },
      {
        kanji:
          "・謎の答え・ヒントをSNSやブログ等で公開するなどの行為はご遠慮ください。",
        furigana:
          '・<ruby data-ruby="なぞ">謎</ruby>の<ruby data-ruby="こた">答</ruby>え・ヒントをSNSやブログ<ruby data-ruby="とう">等</ruby>で<ruby data-ruby="こうかい">公開</ruby>するなどの<ruby data-ruby="こうい">行為</ruby>はご<ruby data-ruby="えんりょ">遠慮</ruby>ください。',
      },
      {
        kanji: "・再開する時は同じ端末・ブラウザからアクセスしてください。",
        furigana:
          '・<ruby data-ruby="さいかい">再開</ruby>する<ruby data-ruby="とき">時</ruby>は<ruby data-ruby="おな">同</ruby>じ<ruby data-ruby="たんまつ">端末</ruby>・ブラウザからアクセスしてください。',
      },
      {
        kanji: "・Cookieを有効にしてください。",
        furigana:
          '・Cookieを<ruby data-ruby="ゆうこう">有効</ruby>にしてください。',
      },

      {
        kanji:
          "・端末や設定、通信状況により履歴を保存できない場合がありますのでご了承ください。",
        furigana: "",
      },
      {
        kanji:
          "・QRコードの読み取り方法等、端末に関するご不明点はご契約中の各キャリアまでお問合せください。",
        furigana:
          '・QRコードの<ruby data-ruby="よ">読</ruby>み<ruby data-ruby="と">取</ruby>り<ruby data-ruby="ほうほう">方法</ruby><ruby data-ruby="とう">等</ruby>、<ruby data-ruby="たんまつ">端末</ruby>に<ruby data-ruby="かん">関</ruby>するご<ruby data-ruby="ふめい">不明</ruby><ruby data-ruby="てん">点</ruby>はご<ruby data-ruby="けいやく">契約</ruby><ruby data-ruby="ちゅう">中</ruby>の<ruby data-ruby="かく">各</ruby>キャリアまでお<ruby data-ruby="といあわ">問合</ruby>せください。',
      },
      {
        kanji:
          "・謎の答え・ヒントについてのご質問は各施設の従業員では一切お受けできませんので、予めご了承ください。",
        furigana:
          '・<ruby data-ruby="なぞ">謎</ruby>の<ruby data-ruby="こた">答</ruby>え・ヒントについてのご<ruby data-ruby="しつもん">質問</ruby>は<ruby data-ruby="かく">各</ruby><ruby data-ruby="しせつ">施設</ruby>の<ruby data-ruby="じゅうぎょう">従業</ruby><ruby data-ruby="いん">員</ruby>では<ruby data-ruby="いっさい">一切</ruby>お<ruby data-ruby="う">受</ruby>けできませんので、<ruby data-ruby="あらかじ">予</ruby>めご<ruby data-ruby="りょうしょう">了承</ruby>ください。',
      },
      {
        kanji:
          "・移動する時は交通ルールを守り、安全に十分注意してご参加ください。",
        furigana:
          '・<ruby data-ruby="いどう">移動</ruby>する<ruby data-ruby="とき">時</ruby>は<ruby data-ruby="こうつう">交通</ruby>ルールを<ruby data-ruby="まも">守</ruby>り、<ruby data-ruby="あんぜん">安全</ruby>に<ruby data-ruby="じゅうぶん">十分</ruby><ruby data-ruby="ちゅうい">注意</ruby>してご<ruby data-ruby="さんか">参加</ruby>ください。',
      },
      {
        kanji:
          "・歩きながらのスマートフォンのご使用は極力おやめください。画面を見るときは周りの方の迷惑にならない安全な場所に立ち止まってください。",
        furigana:
          '・<ruby data-ruby="ある">歩</ruby>きながらのスマートフォンのご<ruby data-ruby="しよう">使用</ruby>は<ruby data-ruby="きょくりょく">極力</ruby>おやめください。<ruby data-ruby="がめん">画面</ruby>を<ruby data-ruby="み">見</ruby>るときは<ruby data-ruby="まわ">周</ruby>りの<ruby data-ruby="ほう">方</ruby>の<ruby data-ruby="めいわく">迷惑</ruby>にならない<ruby data-ruby="あんぜん">安全</ruby>な<ruby data-ruby="ばしょ">場所</ruby>に<ruby data-ruby="た">立</ruby>ち<ruby data-ruby="ど">止</ruby>まってください。',
      },
      {
        kanji:
          "・私有地や建物など許可なく立ち入ってはいけない場所には入らないでください。",
        furigana:
          '・<ruby data-ruby="しゆうち">私有地</ruby>や<ruby data-ruby="たてもの">建物</ruby>など<ruby data-ruby="きょか">許可</ruby>なく<ruby data-ruby="た">立</ruby>ち<ruby data-ruby="い">入</ruby>ってはいけない<ruby data-ruby="ばしょ">場所</ruby>には<ruby data-ruby="はい">入</ruby>らないでください。',
      },
      {
        kanji:
          "・他の方の迷惑になるような行動や、不審に思われるような言動や行動はご遠慮ください。",
        furigana:
          '・<ruby data-ruby="た">他</ruby>の<ruby data-ruby="ほう">方</ruby>の<ruby data-ruby="めいわく">迷惑</ruby>になるような<ruby data-ruby="こうどう">行動</ruby>や、<ruby data-ruby="ふしん">不審</ruby>に<ruby data-ruby="おも">思</ruby>われるような<ruby data-ruby="げんどう">言動</ruby>や<ruby data-ruby="こうどう">行動</ruby>はご<ruby data-ruby="えんりょ">遠慮</ruby>ください。',
      },
      {
        kanji:
          "・イベント参加中の事故や怪我、器物破損などで発生した損害につきましては、参加者個人の責任となりますので、ご理解の上ご参加ください。",
        furigana:
          '・イベント<ruby data-ruby="さんか">参加</ruby><ruby data-ruby="ちゅう">中</ruby>の<ruby data-ruby="じこ">事故</ruby>や<ruby data-ruby="けが">怪我</ruby>、<ruby data-ruby="きぶつ">器物</ruby><ruby data-ruby="はそん">破損</ruby>などで<ruby data-ruby="はっせい">発生</ruby>した<ruby data-ruby="そんがい">損害</ruby>につきましては、<ruby data-ruby="さんか">参加</ruby><ruby data-ruby="しゃ">者</ruby><ruby data-ruby="こじん">個人</ruby>の<ruby data-ruby="せきにん">責任</ruby>となりますので、ご<ruby data-ruby="りかい">理解</ruby>の<ruby data-ruby="うえ">上</ruby>ご<ruby data-ruby="さんか">参加</ruby>ください。',
      },
      {
        kanji:
          "・参加に当たっては、新型コロナウイルスの感染症対策の徹底をお願いいたします。",
        furigana:
          '・<ruby data-ruby="さんか">参加</ruby>に<ruby data-ruby="あ">当</ruby>たっては、<ruby data-ruby="しんがた">新型</ruby>コロナウイルスの<ruby data-ruby="かんせん">感染</ruby><ruby data-ruby="しょう">症</ruby><ruby data-ruby="たいさく">対策</ruby>の<ruby data-ruby="てってい">徹底</ruby>をお<ruby data-ruby="ねが">願</ruby>いいたします。',
      },
      {
        kanji:
          "・当イベントは予告なく中止、変更する場合があります。また景品についても変更になる場合がありますのでご了承ください。",
        furigana:
          '・<ruby data-ruby="とう">当</ruby>イベントは<ruby data-ruby="よこく">予告</ruby>なく<ruby data-ruby="ちゅうし">中止</ruby>、<ruby data-ruby="へんこう">変更</ruby>する<ruby data-ruby="ばあい">場合</ruby>があります。また<ruby data-ruby="けいひん">景品</ruby>についても<ruby data-ruby="へんこう">変更</ruby>になる<ruby data-ruby="ばあい">場合</ruby>がありますのでご<ruby data-ruby="りょうしょう">了承</ruby>ください。',
      },
      {
        kanji: "・QRコードは（株）デンソーウェーブの登録商標です。",
        furigana:
          '・QRコードは<ruby data-ruby="かぶしきがいしゃ">（株）</ruby>デンソーウェーブの<ruby data-ruby="とうろく">登録</ruby><ruby data-ruby="しょうひょう">商標</ruby>です。',
      },
      {
        kanji: "・推奨環境は以下の通りです。",
        furigana:
          '・<ruby data-ruby="すいしょう">推奨</ruby><ruby data-ruby="かんきょう">環境</ruby>は<ruby data-ruby="いか">以下</ruby>の<ruby data-ruby="とお">通</ruby>りです。',
      },
      { kanji: "…………………………………………………", furigana: "…………………………………………………" },
      { kanji: "スマートフォン", furigana: "スマートフォン" },
      {
        kanji:
          "【iPhone】OS：iOS 13以降　ブラウザ：Safari / GoogleChrome 最新版",
        furigana:
          '【iPhone】OS：iOS 13<ruby data-ruby="いこう">以降</ruby>　ブラウザ：Safari / GoogleChrome <ruby data-ruby="さいしん">最新</ruby><ruby data-ruby="ばん">版</ruby>',
      },
      {
        kanji: "【Android】OS：Android 8以降　ブラウザ：GoogleChrome 最新版",
        furigana:
          '【Android】OS：Android 8<ruby data-ruby="いこう">以降</ruby>　ブラウザ：GoogleChrome <ruby data-ruby="さいしん">最新</ruby><ruby data-ruby="ばん">版</ruby>',
      },
      { kanji: "………………………………………………… ", furigana: "………………………………………………… " },
    ],
  },
];
