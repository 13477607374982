import { Popover } from "@headlessui/react";
import { buttonTexts } from "../../../../common/const/buttonTexts";
import { divTexts } from "../../../../common/const/divTexts";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import { UserGainedTicket } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import PopoverPanel from "../../../shared/PopoverPanel";
import { useTicketState } from "../../hooks/modalHooks";
import styles from "../ModalContents.module.scss";

interface Props {
  userGainedTicket: UserGainedTicket;
}

const OnlyOnce: React.FC<Props> = ({ userGainedTicket }) => {
  const { updateUserState } = useUserStateHooks();
  const { ticketNameMV, ticketImageUrl, ticketTextMVArray, index } =
    userGainedTicket;

  const { outOfStock, isUsable, setIsUsable } =
    useTicketState(userGainedTicket);

  // [ ] 画像の高さについて応急処置中
  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={ticketNameMV} />
        </div>
      </div>
      <div className={`${styles.imgWrap}`}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={ticketImageUrl}
              alt={ticketNameMV?.kanji}
              width={400}
              height={200}
            />
          </div>
        </div>
      </div>
      <div className={styles.text}>
        {ticketTextMVArray.map((itemTextMV, index) => {
          if (index === 0) return <div key={index} />;
          return (
            <div key={itemTextMV?.kanji + index}>
              <MultipleViewText text={itemTextMV} />
            </div>
          );
        })}
      </div>
      {/* チケットの残数情報取得までは灰色の明滅、取得後は状況に応じてボタンかテキストを表示 */}
      {isUsable === null ? (
        <div className="h-9 animate-pulse bg-slate-400" />
      ) : (
        <div className={styles.btnUseWrap}>
          {isUsable ? (
            <PopoverPanel
              bgColor=""
              buttonText={buttonTexts.使用するMV}
              className={styles.btnUse}
            >
              <div className={styles.popupBox}>
                <div>
                  <MultipleViewText text={divTexts.この操作はMV} />
                </div>
                <div className={styles.btns}>
                  <button
                    type="button"
                    onClick={() => {
                      updateUserState("useTicket", index);
                      setIsUsable(false);
                    }}
                    className={`${styles.btnNext} heartbeat`}
                    onTouchStart={() => {
                      return {};
                    }}
                  >
                    <MultipleViewText text={buttonTexts.進めるMV} />
                  </button>
                  <Popover.Button className="">
                    <MultipleViewText text={buttonTexts.閉じるMV} />
                  </Popover.Button>
                </div>
              </div>
            </PopoverPanel>
          ) : outOfStock ? (
            <div className={styles.done}>
              <MultipleViewText text={divTexts.先着に達したためMV} />
            </div>
          ) : (
            <div className={styles.done}>
              <MultipleViewText text={divTexts.使用済みMV} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OnlyOnce;
