import { doc, onSnapshot } from "firebase/firestore";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useRecoilState, SetterOrUpdater } from "recoil";
import { converter, firestore } from "../../../common/lib/firebase";
import {
  ModalType,
  ModalVisibilityState,
} from "../../../common/recoil/modalAtoms";
import { TicketCount } from "../../../common/types/modalTypes";
import { UserGainedTicket } from "../../../common/types/userTypes";

type Response = [boolean, SetterOrUpdater<boolean>];

export const useModal = (modalType: ModalType): Response => {
  const [isVisible, setIsVisible] = useRecoilState(
    ModalVisibilityState(modalType)
  );

  return [isVisible, setIsVisible];
};

const useTicketCount = (index: string): TicketCount | null => {
  const [ticketCount, setTicketCount] = useState<TicketCount | null>(null);
  const router = useRouter();

  const {
    query: { ver, orgId, course },
  } = router;

  useEffect(() => {
    const ref = doc(
      firestore,
      `${ver}/${orgId}/courses/${course}/ticketCount/${index}`
    ).withConverter(converter<TicketCount>());

    const unsub = onSnapshot(ref, (snap) => {
      if (snap.exists()) {
        console.log("ticketCount", snap.data());
        setTicketCount(snap.data());
      }
    });

    return () => {
      if (unsub) return unsub();
    };
  }, [course, index, orgId, ver]);

  return ticketCount;
};

export const useTicketState = (userGainedTicket: UserGainedTicket) => {
  const [isUsable, setIsUsable] = useState<boolean | null>(null);
  const [outOfStock, setOutOfStock] = useState<boolean>(true);

  const { isUsed, index, distributeStock, usableStock } = userGainedTicket;

  const ticketCount = useTicketCount(index);

  useEffect(() => {
    if (!ticketCount) return;

    const { distributed, used } = ticketCount;

    // 使用前で配布制限か使用数制限に引っ掛かっている時
    const check =
      !isUsed &&
      ((distributeStock !== 0 && distributed >= distributeStock) ||
        (usableStock !== 0 && used >= usableStock));

    setOutOfStock(check);

    // 配布数制限と使用数制限がなく、未使用の時
    if (
      isUsable === null &&
      distributeStock === 0 &&
      usableStock === 0 &&
      !isUsed
    )
      return setIsUsable(true);

    // 配布数制限があり、まだ在庫があり、未使用の時
    if (
      isUsable === null &&
      distributeStock &&
      distributed < distributeStock &&
      !isUsed
    )
      return setIsUsable(true);

    // 使用数制限があり、まだ在庫があり、未使用の時
    if (isUsable === null && usableStock && used < usableStock && !isUsed)
      return setIsUsable(true);

    if (isUsable === null) return setIsUsable(false);
  }, [distributeStock, isUsable, isUsed, ticketCount, usableStock]);

  return { outOfStock, isUsable, setIsUsable };
};
