import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { ModalDataState } from "../../../common/recoil/modalAtoms";
import { isHelp } from "../../../common/types/typeGuard";
import ModalTemplate from "../../../templates/ModalTemplate";
import ImageComponent from "../../shared/ImageComponent";
import { useModal } from "../hooks/modalHooks";
import Default from "./infoHelpContents/Default";
import GpsHeading from "./infoHelpContents/GpsHeading";
import Inquiry from "./infoHelpContents/Inquiry";
import Membership from "./infoHelpContents/Membership";
import PrivacyPolicy from "./infoHelpContents/PrivacyPolicy";
import QuestHint from "./infoHelpContents/QuestHint";
import RestoreMembership from "./infoHelpContents/RestoreMembership";
import Setting from "./infoHelpContents/Setting";
import Precaution from "./infoOthersContents/Precaution";
import styles from "./ModalContents.module.scss";

const InfoHelp: React.FC = () => {
  const [isVisible, setIsVisible] = useModal("infoHelp");
  const [modalData, setModalData] = useRecoilState(ModalDataState);
  const router = useRouter();

  const {
    query: { room },
  } = router;

  // リセットでトップページに行った時の対処
  useEffect(() => {
    if (!room) {
      setIsVisible(false);
      setModalData(null);
    }
  }, [room, setIsVisible, isVisible, setModalData]);

  // [ ] ヘルプたくさんありますが、凝らずに極力使い回しで大丈夫です
  const renderContent = useMemo(() => {
    // modalDataがunionなので型判定
    if (!isHelp(modalData)) return null;

    const { type } = modalData;

    if (type === "default") return <Default help={modalData} />;
    if (type === "questHint") return <QuestHint help={modalData} />;
    if (type === "inquiry") return <Inquiry help={modalData} />;
    if (type === "setting") return <Setting />;
    if (type === "membership") return <Membership help={modalData} />;
    if (type === "restoreMembership")
      return <RestoreMembership help={modalData} />;
    if (type === "gpsHeading") return <GpsHeading help={modalData} />;
    if (type === "privacyPolicy") return <PrivacyPolicy />;

    // precaution（利用規約・注意事項）はinfoOthersContentのものを再利用
    if (type === "precaution") return <Precaution />;
  }, [modalData]);

  return (
    <>
      {isVisible && (
        <ModalTemplate
          bgCloseFunction={() => {
            setModalData(null);
            setIsVisible(false);
          }}
        >
          <div className={styles.modalBody}>{renderContent}</div>
          <div className={styles.btnModalClose}>
            <button
              type="button"
              onClick={() => {
                setModalData(null);
                setIsVisible(false);
              }}
            >
              <ImageComponent
                url="/images/btn/default/btn_close01"
                alt="閉じるボタン"
                width={36}
                height={36}
                className="object-cover"
                withBlur={false}
              />
            </button>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default InfoHelp;
