import { TextMV } from "../types/commonTypes";

export const toastTexts: { [key: string]: TextMV } = {
  答えが入力されてMV: {
    kanji: "答えが入力されていません。",
    furigana:
      '<ruby data-ruby="こた">答</ruby>えが<ruby data-ruby="にゅうりょく">入力</ruby>されていません。',
  },
  ひらがな以外のMV: {
    kanji: "ひらがな以外の文字が含まれています。",
    furigana:
      'ひらがな<ruby data-ruby="いがい">以外</ruby>の<ruby data-ruby="もじ">文字</ruby>が<ruby data-ruby="ふく">含</ruby>まれています。',
  },
  カタカナ以外のMV: {
    kanji: "カタカナ以外の文字が含まれています。",
    furigana:
      'カタカナ<ruby data-ruby="いがい">以外</ruby>の<ruby data-ruby="もじ">文字</ruby>が<ruby data-ruby="ふく">含</ruby>まれています。',
  },
  数字以外のMV: {
    kanji: "数字以外の文字が含まれています。",
    furigana:
      '<ruby data-ruby="すうじ">数字</ruby><ruby data-ruby="いがい">以外</ruby>の<ruby data-ruby="もじ">文字</ruby>が<ruby data-ruby="ふく">含</ruby>まれています。',
  },
  ざんねん不正解MV: {
    kanji: "ざんねん！不正解です。",
    furigana:
      'ざんねん！<ruby data-ruby="ふ">不</ruby><ruby data-ruby="せいかい">正解</ruby>です。',
  },
  ざんねん不正解10秒MV: {
    kanji: "ざんねん！不正解です。10秒経つまで選べません。",
    furigana:
      'ざんねん！<ruby data-ruby="ふ">不</ruby><ruby data-ruby="せいかい">正解</ruby>です。10<ruby data-ruby="びょう">秒</ruby><ruby data-ruby="た">経</ruby>つまで<ruby data-ruby="えら">選</ruby>べません。',
  },
  正解おめでとうMV: {
    kanji: "正解！おめでとう！",
    furigana: '<ruby data-ruby="せいかい">正解</ruby>！おめでとう！',
  },
  回答ありがとうございましたMV: {
    kanji: "回答ありがとうございました",
    furigana: "",
  },
  IDまたはパスワードMV: {
    kanji: "IDまたはパスワードが入力されていません。",
    furigana: "",
  },
  英数字以外のMV: {
    kanji: "英数字以外の文字が含まれています。",
    furigana: "",
  },
  パスワードが一致しませんMV: {
    kanji: "パスワードが一致しません。",
    furigana: "",
  },
  このIDは使用できませんMV: {
    kanji: "このIDは使用できません。",
    furigana: "",
  },
  登録が完了しましたMV: {
    kanji: "登録が完了しました。トップページに戻ります。",
    furigana: "",
  },
  このIDで登録されたMV: {
    kanji: "このIDで登録されたアカウントはありません。",
    furigana: "",
  },
  パスワードが違うMV: {
    kanji: "パスワードが間違っています。",
    furigana: "",
  },
  パスワードが短いMV: {
    kanji: "パスワードが短すぎます",
    furigana: "",
  },
  復元に成功しましたMV: {
    kanji: "復元に成功しました。トップページに戻ります。",
    furigana: "",
  },
  保存に失敗MV: {
    kanji:
      "クリア履歴の保存に失敗しました。ネットワークに接続していないか、端末/Wifiのセキュリティ設定に影響されている可能性があります。端末の設定をご確認ください。",
    furigana: "",
  },
};
